import axios from 'axios'
import Vue from 'vue'
import router from '@/router'

export default {
  createUser: payload => {
    let signUpData = payload.user
    let data = new window.FormData()
    data.append('user[first_name]', signUpData.firstName)
    data.append('user[last_name]', signUpData.lastName)
    data.append('user[email]', signUpData.email)
    data.append('user[password]', signUpData.password)
    data.append('user[company]', signUpData.company)
    data.append('user[company_type]', signUpData.companyType)
    data.append('user[title]', signUpData.title)
    data.append('user[phone]', signUpData.phone)
    data.append('user[is_approved]', false)
    return axios
      .post('/api/users', data, { handleErrors: false })
      .then(() => {
        Vue.toasted.show(
          'Access Request successfully submitted, verify email to continue.'
        )
        if (!payload.skipRedirect) {
          router.push('/sign_in?activation=request_submitted')
        }
        return true
      })
      .catch(error => {
        if (error.response.status === 400) {
          Vue.toasted.show(error.response.data.message, {
            icon: 'user-times',
            type: 'error'
          })
        } else {
          Vue.toasted.show(
            'Sorry, an error has occurred with registration. Please try again. If the problem continues, please know that our team has been alerted.',
            {
              icon: 'chain-broken',
              type: 'error'
            }
          )
        }
      })
  }
}
