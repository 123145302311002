<template>
  <div class="terms-of-service">
    <p>
      The following terms and conditions ("Terms of Service") together with any
      defined services quotation, purchase order, or order form (collectively,
      the "Order") govern all use of the FI Navigator Services. Please read
      these Terms of Service carefully before using the Services.
    </p>
    <p>
      This Agreement ("Agreement") is made by and between FI Navigator
      Corporation, a Georgia corporation ("FI Navigator"), and the entity or
      individual entering into this Agreement (“Client”). The Agreement consists
      of this Terms of Service and any accepted Orders.
    </p>
    <p>
      CLIENT MAY ACCEPT THE TERMS OF THIS AGREEMENT BY CLICKING A BOX INDICATING
      ACCEPTANCE, BY EXECUTING THIS AGREEMENT, BY EXECUTING AN ORDER FORM,
      PURCHASE ORDER, OR QUOTATION REFERENCING THIS AGREEMENT, OR BY USING ANY
      FREE SERVICE OFFERED BY FI NAVIGATOR. IF CLIENT DOES NOT ACCEPT THE TERMS
      OF THIS AGREEMENT, CLIENT MAY NOT USE THE CONTENT OR SERVICES.
    </p>
    <p>
      ANY INDIVIDUAL ACCEPTING THE TERMS OF THIS AGREEMENT ON BEHALF OF A LEGAL
      ENTITY MUST HAVE THE AUTHORITY TO BIND THAT ENTITY AND ITS AFFILIATES TO
      THIS AGREEMENT. IF THE INDIVIDUAL DOES NOT HAVE SUCH AUTHORITY, THE
      INDIVIDUAL MUST NOT ACCEPT THIS AGREEMENT.
    </p>
    <p>
      This Agreement and the initial term shall be effective upon execution
      ("Effective Date"), unless otherwise specified in the Order.
    </p>
    <ol class="l1-list">
      <li>
        <b><span>1)</span>Agreement Scope</b>
        <ol type="a" class="l2-list">
          <li>
            <u>Authorized User.</u>The rights granted herein are granted only to
            Client, and do not extend to related entities or persons not
            included in the definition of Client on the Order.
          </li>
          <li>
            <u>Licensed Materials.</u>The licensed materials ("Licensed
            Materials") covered by this Agreement include information produced
            by FI Navigator, or obtained by FI Navigator from publicly available
            data or third-party suppliers, and made available to the Client
            pursuant to the Agreement including electronic publications, data,
            databases, software, interfaces and documentation delivered through
            the Website or other channels.
          </li>
          <li>
            <u>Services.</u> The services (“Services”) provided by FI Navigator
            include any websites operated by FI Navigator, including
            fi-navigator.com and finavigator.com, (“Website”), any means of
            providing Licensed Materials (including email, data query, direct
            file download, printed document, electronic document, FTP, or other
            channels), training, support, or other services defined in an Order.
          </li>
        </ol>
      </li>
      <li>
        <b><span>2)</span>Ownership</b>
        <ol type="a" class="l2-list">
          <li>
            <u>Ownership of Licensed Materials.</u>Client acknowledges and
            agrees that FI Navigator and/or its third-party suppliers are the
            sole owner(s) of the Licensed Materials and further that they
            constitute the valuable trade secrets and confidential information
            of FI Navigator, provided to Client in confidence. Except as
            provided herein, Client shall not obtain, have or retain any right,
            title, or interest in or to the Licensed Materials, any components,
            modifications, adaptations or copies thereof including any pro forma
            materials developed as a result of Client request or feedback.
            Client may not reverse engineer, decompile or otherwise attempt to
            access any code in connection with the Licensed Materials.
          </li>
          <li>
            <u>Authority to Grant.</u> FI Navigator owns or has the right to
            distribute the Licensed Materials pursuant to this Agreement. To the
            knowledge of FI Navigator, the use by Client of the Licensed
            Materials in accordance with this Agreement will not infringe any
            third-party patent, copyright, trade secret or other intellectual
            property rights.
          </li>
        </ol>
      </li>
      <li>
        <b><span>3)</span>License Grant</b>
        <div>
          FI Navigator grants to Client a non-exclusive and non-transferable
          limited license to use the Licensed Materials, subject to the terms
          and conditions of this Agreement.
        </div>
        <ol type="a" class="l2-list">
          <li>
            <u>Authorized Uses.</u> Client may:
            <ol type="i" class="l3-list">
              <li>
                Extract and distribute Licensed Materials internally, either in
                original or modified form, and use data, interfaces and
                publications comprising the Licensed Materials;
              </li>
              <li>
                Excerpt and distribute in hard copy or electronic form to third
                parties limited, insubstantial portions of data from the
                Licensed Materials to support the primary business of Client
                (“Allowable Distribution”). Allowable Distribution of the data
                to third parties is also subject to the following additional
                restrictions:
                <ol class="l4-list">
                  <li>
                    Client may not distribute data from the Licensed Materials
                    in a quantity or in a manner that serves as a substitute for
                    purchase of Licensed Materials from FI Navigator;
                  </li>
                  <li>
                    Client may not re-sell the data via distribution to third
                    parties without written permission from FI Navigator;
                  </li>
                  <li>
                    Any distribution shall include citation similar to: “Source:
                    FI Navigator Corporation. All rights reserved;”
                  </li>
                </ol>
              </li>
            </ol>
          </li>
          <li>
            <u>Rights Reserved.</u> FI Navigator reserves all rights in the
            Licensed Materials not expressly granted herein.
          </li>
        </ol>
      </li>
      <li>
        <b><span>4)</span>Payments</b>
        <ol type="a" class="l2-list">
          <li>
            Client shall pay FI Navigator per the terms of the Order.
          </li>
          <li>
            If Client chooses to make payment by credit card, Client shall grant
            permission to FI Navigator to charge Client's credit card per the
            terms of the Order until FI Navigator or Client terminates the
            Service.
          </li>
          <li>
            The pricing provided in the Order by FI Navigator, both for its
            elements and in the aggregate is FI Navigator's Confidential
            Information (as hereinafter defined).
          </li>
        </ol>
      </li>
      <li>
        <b><span>5)</span>Confidentiality</b>
        <div>
          The Licensed Materials constitute and are comprised of the
          confidential and trade secret information of FI Navigator and its
          third-party suppliers ("Confidential Information"), and Client agrees
          not to use or disclose the Licensed Materials other than as is
          expressly authorized by this Agreement. Confidential Information does
          not include information:
        </div>
        <ol type="a" class="l2-list padding-2">
          <li>known to Client at the time of disclosure;</li>
          <li>
            disclosed to Client by a third party without confidentiality
            obligation to FI Navigator;
          </li>
          <li>that is, or becomes, generally known;</li>
          <li>
            that Client independently develops without reference to the
            Confidential Information;
          </li>
          <li>
            required by law or regulation to be disclosed by Client to the
            extent so disclosed.
          </li>
        </ol>
        <div>
          Client agrees to safeguard the Licensed Materials against unauthorized
          use or disclosure with means at least as stringent as those it uses to
          safeguard its own confidential information, and in no event with less
          than reasonable means. The obligations of confidentiality in this
          Agreement shall survive its termination without limitation in duration
          for so long as Client is in possession of any part of the Licensed
          Materials.
        </div>
      </li>
      <li>
        <b><span>6)</span>Limited Warranty, Disclaimer and Limitations</b>
        <ol type="a" class="l2-list">
          <li>
            <u>Limited Warranty.</u> The information supplied under this
            Agreement:
            <ol type="i" class="l3-list">
              <li>
                has been gathered by FI Navigator in a manner and from sources
                believed by FI Navigator to be reliable;
              </li>
              <li>
                has been arranged by FI Navigator in a way that FI Navigator
                believes will increase the ease of access, use and utility of
                the information.
              </li>
            </ol>
            <div>
              However, FI Navigator does not warrant as to the accuracy,
              completeness, contemporaneity, condition, quality, performance,
              merchantability or fitness for a particular purpose of the
              information in the Licensed Materials, and it does not warrant
              that the interface used to access the Licensed Materials will be
              error free or bug free.
            </div>
          </li>
          <li>
            <u>DISCLAIMER.</u> EXCEPT AS IS EXPRESSLY WARRANTED HEREIN, THE
            LICENSED MATERIALS ARE PROVIDED "AS IS," WITHOUT ANY WARRANTY OF ANY
            KIND. FI NAVIGATOR AND ITS THIRD PARTY PROVIDERS HEREBY DISCLAIM ALL
            OTHER WARRANTIES, EXPRESS OR IMPLIED, INCLUDING WITHOUT LIMIT
            IMPLIED WARRANTIES OF MERCHANTABILITY AND FITNESS FOR ANY PARTICULAR
            PURPOSE. WITHOUT LIMITING ANY OF THE FOREGOING, FI NAVIGATOR MAKES
            NO REPRESENTATION OR WARRANTY THAT THE LICENSED MATERIALS OR ANY
            RESULTS, DATA OR COMPILATION OBTAINED THROUGH OR BY USE OF THE
            LICENSED MATERIALS WILL BE ACCURATE, COMPLETE, RELIABLE, TIMELY,
            SECURE, SUITABLE FOR CLIENT’S PURPOSES OR FREE FROM ERROR, DEFECT,
            OMISSIONS, DELAYS, INTERRUPTIONS OR LOSSES, INCLUDING INADVERTENT
            LOSS OF DATA OR DAMAGE TO MEDIA. CLIENT FURTHER ACKNOWLEDGES AND
            AGREES THAT IN NO EVENT WILL FI NAVIGATOR BE RESPONSIBLE OR LIABLE
            TO CLIENT OR TO ANY THIRD PARTY FOR CLIENT’S USE OF OR RELIANCE ON
            ANY RESULTS OBTAINED FROM OR PROVIDED BY THE LICENSED MATERIALS OR
            FI NAVIGATOR, AND THAT CLIENT BEARS THE SOLE RESPONSIBILITY AND RISK
            FOR ANY SUCH USE OR RELIANCE (INCLUDING WITHOUT LIMITATION ANY
            DECISION(S) CLIENT MAY MAKE OR REFRAIN FROM MAKING IN CONNECTION
            THEREWITH).
          </li>
          <li>
            <u>LIMITATION OF LIABILITY.</u> WITH THE EXCEPTION OF OBLIGATIONS OF
            SECTION 4 (CONFIDENTIALALITY), IN NO EVENT SHALL EITHER PARTY BE
            LIABLE FOR ANY SPECIAL, INDIRECT, INCIDENTAL, CONSEQUENTIAL OR
            PUNITIVE DAMAGES (INCLUDING, WITHOUT LIMITATION, DAMAGES FOR LOSS OF
            PROFITS, BUSINESS INTERRUPTION, LOSS OF DATA OR BUSINESS
            INFORMATION) WHETHER BASED ON CONTRACT, TORT, OR OTHER LEGAL THEORY,
            IN CONNECTION WITH THIS AGREEMENT OR THE USE OR INABILITY TO USE THE
            LICENSED MATERIALS.
          </li>
        </ol>
      </li>
      <li>
        <b><span>7)</span>Indemnity</b>
        <ol type="a" class="l2-list">
          <li>
            FI Navigator shall indemnify Client and hold it harmless against all
            claims, causes of action, judgments, damages, fines or expenses
            (including reasonable attorneys’ fees) arising from a third party
            claim that Client’s use of the Licensed Materials in accordance with
            this Agreement infringes upon or otherwise violates such
            third-party’s patent, copyright, trade secret or other intellectual
            property rights.
          </li>
          <li>
            Client shall indemnify FI Navigator and its third party providers
            and hold them harmless against all claims, causes of actions,
            judgments, damages, fines or expenses (including reasonable
            attorneys’ fees) arising from a third-party claim relating to
            Client’s use or distribution of the Licensed Materials, except to
            the extent such claim is a result of FI Navigator’s breach of this
            Agreement or conduct or events for which FI Navigator has the
            obligation to indemnify under Section 6(a).
          </li>
          <li>
            The obligations of indemnity herein are contingent on a party giving
            prompt notice of any claim for which it seeks indemnity. An
            indemnified party shall provide the indemnifying party with
            reasonable non-monetary assistance in the defense of the claims on
            which indemnity is sought. The indemnifying party shall have the
            right to assume the defense of the claim, and the indemnifying party
            may select counsel of its choice, subject to the approval of the
            indemnified party, which consent shall not be unreasonably withheld.
            A party shall not be obligated to indemnify the other in the event
            the claim for which indemnity is sought arises from the other’s
            gross negligence, willful misconduct, or breach of this Agreement.
          </li>
        </ol>
      </li>
      <li>
        <b><span>8)</span>Term and Termination</b>
        <ol type="a" class="l2-list">
          <li>
            Unless terminated as provided for herein, the term of the Agreement
            shall be term stated in the Order. If no term is stated within the
            Order, this Agreement and the license granted herein shall run for a
            one (1) year term commencing on the Effective Date.
          </li>
          <li>
            Either party may terminate this Agreement upon a material or
            continuing breach or default by the other by giving fifteen (15)
            days prior written notice of termination, and termination shall be
            effective at the end of such fifteen (15) day period unless the
            breach is then cured to the satisfaction of the terminating party. A
            termination pursuant to this Section shall not preclude the recovery
            of damages permitted by this Agreement by the party not in breach.
            During any period of time after a party has breached this Agreement,
            the other party may suspend performance until the breach has been
            cured.
          </li>
          <li>
            Termination or expiration of this Agreement shall cause termination
            or expiration of all licenses granted herein. However, termination
            or expiration of this Agreement shall not relieve Client of its
            obligation to pay all amounts due pursuant to invoices issued under
            this Agreement.
          </li>
          <li>
            Upon termination of this Agreement, Client shall immediately cease
            accessing and using the Licensed Materials and shall promptly
            destroy all Licensed Materials in Client’s possession, except that
            Client may retain print copies of reports generated from the
            Licensed Materials before the effective date of termination. Client
            may not make any use of electronic databases provided during a
            subscription term after termination or expiration of that
            subscription, but may retain and make limited distribution of print
            copies of excerpted data and reports containing excerpted data as
            allowed herein. Notwithstanding the foregoing, Client is prohibited
            from using the Licensed Materials after termination of this
            Agreement in any other manner, including but not limited to,
            converting print copies to digital form or distribution to third
            parties of new work product incorporating all or any portions of the
            Licensed Materials. In the event this Agreement is terminated
            because Client violated FI Navigator’s intellectual property rights,
            Client shall return all copies of Licensed Materials, including all
            documents comprised in part of Licensed Materials.
          </li>
        </ol>
      </li>
      <li>
        <b><span>9)</span>General Terms</b>
        <ol type="a" class="l2-list">
          <li>
            <u>Entire Agreement.</u> This Agreement (including the associated
            accepted Order and other agreements, which are incorporated by
            reference) is the entire agreement between the parties with respect
            to its subject matter, and this Agreement supersedes all prior
            agreements, understandings and representations made by and between
            the parties with respect to the subject matter of this Agreement.
          </li>
          <li>
            <u>Non-Assignment.</u> Client may not assign or transfer this
            Agreement or its rights or obligations to another party without the
            express prior written consent of FI Navigator.
          </li>
          <li>
            <u>Dispute Resolution.</u>
            <ol type="i" class="l3-list">
              <li>
                This Agreement shall be governed and construed by the laws of
                the State of Georgia, without regard to its choice of law rules,
                and the parties expressly agree that the Uniform Computer
                Information Transactions Act shall not apply to this Agreement.
              </li>
              <li>
                The parties to this Agreement consent to the jurisdiction and
                venue of the courts of the State of Georgia in connection with
                any and all actions arising out of this Agreement.
              </li>
              <li>
                In the event of any dispute adjudicated between the parties,
                whether in litigation or permitted appeal, the prevailing party
                shall be entitled to recover from the party not prevailing its
                reasonable attorneys’ fees and costs incurred in such
                proceeding.
              </li>
              <li>
                The parties agree that neither may bring a claim nor assert a
                cause of action against the other, in any forum or manner, more
                than one (1) year after the cause of action accrued, except
                where the party could not have reasonably discovered the wrong
                giving rise to the claim within one year.
              </li>
            </ol>
          </li>
          <li>
            <u>Severability.</u> Should any term of this Agreement be finally
            held by a court of competent jurisdiction to be invalid,
            unenforceable, void or otherwise contrary to law or equity, the
            parties agree that such provision shall be automatically severed and
            the remainder of this Agreement that can be given effect shall
            continue to be given effect.
          </li>
          <li>
            <u>Survival.</u> The provisions of Sections 2, 3, 4, 5, 6, 7(d), and
            8 shall survive the termination or expiration of this Agreement. Any
            other obligations under this Agreement which by their nature would
            continue beyond the termination, cancellation or expiration of this
            Agreement shall survive termination, cancellation or expiration of
            this Agreement.
          </li>
        </ol>
      </li>
    </ol>
  </div>
</template>

<script>
export default {
  name: 'TermsOfService'
}
</script>

<style lang="scss" scoped>
ol {
  &.l1-list {
    list-style: none;
    padding-left: 0;
    & > li {
      padding-bottom: 1em;
      span {
        margin-right: 1em;
      }
      div {
        padding-left: 2em;
        padding-top: 0.2em;
      }
    }
  }
  &.l2-list {
    padding-left: 3em;
    & > li {
      padding-bottom: 0.5em;
    }
    &.padding-2 {
      padding-top: 0.5em;
      padding-bottom: 0.5em;
      & > li {
        padding-bottom: 0;
      }
    }
  }
}
ol.l2-list {
  list-style-type: lower-alpha;
  counter-reset: item;
  li {
    position: relative;
    padding-left: 1em;
  }
  li:before {
    display: inline-block;
    width: 7px;
    position: absolute;
    left: -8px;
    content: ')';
    background-color: #fff;
    text-align: center;
  }
}
ol.l3-list,
ol.l4-list {
  padding-left: 1em;
  padding-top: 0.5em;
}
</style>
